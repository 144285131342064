<template>
  <div>
    <div class="application">
      <div
        class="line showpc"
        style="height: 60px; border-bottom: solid 1px var(--C3)"
      >
        <div class="line-33">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            "
          >
            <li style="line-height: 60px">
              <img
                src="media/epinal_agglo.jpg"
                alt=""
                style="height: 50px; margin-top: 5px"
              />
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: center;
            "
          >
            <li style="line-height: 60px">
              <img
                src="media/logo.png"
                alt=""
                style="height: 50px; margin-top: 5px"
              />
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            "
          >
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="line showmobile"
        style="height: 60px; border-bottom: solid 1px var(--C3)"
      >
        <div class="line-50">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: left;
              margin-left: 15px;
            "
          >
            <li style="line-height: 60px">
              <img
                src="media/logo.png"
                alt=""
                style="height: 50px; margin-top: 5px"
              />
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul
            style="
              list-style: none;
              padding: 0px;
              margin: 0px;
              text-align: right;
              margin-right: 15px;
            "
          >
            <li style="line-height: 60px">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line" style="text-align: center">
        <h1 style="text-transform: capitalize">
          Bienvenue {{ account.fullname }}
        </h1>
        <h3>
          Gérer votre compte en toute simplicité : <br />consulter vos factures,
          modifier vos informations personnelles et effectuer vos réservations
          de vélos.
        </h3>
      </div>
      <div class="line-m" style="text-align: center; margin-top: 30px">
        <ul class="board-menu">
          <li>
            <div
              v-if="account.fin_contrats < datenow && account.nb_contrats < 4"
              class="board-item"
              @click="navigation('reservation')"
            >
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: var(--C2)"
                  >Effectuer une réservation de vélos et réaliser le paiement en
                  ligne</label
                >
              </div>
            </div>
            <div
              v-if="account.fin_contrats < datenow && account.nb_contrats >= 4"
              class="board-item"
            >
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: red"
                  >Vous avez déjà atteint le maximum de 4 contrats de
                  locations</label
                >
              </div>
            </div>
            <div v-if="account.fin_contrats >= datenow" class="board-item">
              <div class="board-icon">
                <i class="fa fa-plus"></i>
              </div>
              <div class="board-text">
                <h2>Réserver un vélo</h2>
                <label style="color: red"
                  >Vous avez déjà un contrat de location en cours.</label
                >
              </div>
            </div>
          </li>
          <li>
            <div class="board-item" @click="navigation('account')">
              <div class="board-icon">
                <i class="fa fa-user"></i>
              </div>
              <div class="board-text">
                <h2>Votre compte</h2>
                <label style="color: var(--C2)"
                  >Modifier vos informations personnelles et envoyer vos
                  justificatifs</label
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="line-m"
        style="text-align: center; margin-top: 30px; padding-bottom: 30px"
      >
        <ul class="board-menu">
          <li>
            <div class="board-item" @click="navigation('rentals')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>Vos contrats</h2>
                <label style="color: var(--C2)"
                  >Historique de vos contrats de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </li>
          <li>
            <div class="board-item" @click="navigation('invoices')">
              <div class="board-icon">
                <i class="fa fa-file-invoice"></i>
              </div>
              <div class="board-text">
                <h2>Vos factures</h2>
                <label style="color: var(--C2)"
                  >Historique de vos factures de locations de vélos et
                  accessoires</label
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import loadingform from "../core/LoadingForm";

export default {
  components: {
    login,
    loadingform,
  },
  data() {
    return {
      account: {},
      loading: false,
      datenow: "",
    };
  },
  created: function () {
    var tmain = this;
    tmain.loading = true;
    tmain.datenow = tmain.$moment().format("YYYY-MM-DD");
    engine.Account_Info(function (pkg) {
      if (pkg.code != 0) {
        tmain.$router.push("/");
      } else {
        tmain.account = pkg.data[0];
      }
      tmain.loading = false;
    });
  },
  methods: {
    navigation: function (app) {
      var tmain = this;
      tmain.$router.push("/" + app);
    },
  },
};
</script>
<style>
</style>
