<template>
  <div>
    <div class="application">
      <div class="line showpc" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="media/epinal_agglo.jpg" alt="" style="height:50px;margin-top:5px;">
            </li>
          </ul>
        </div>
        <div class="line-34">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:center;">
            <li style="line-height:60px;">
              <img src="media/logo.png" alt="" style="cursor:pointer;height:50px;margin-top:5px;" @click="backAccount">
            </li>
          </ul>
        </div>
        <div class="line-33">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;display:inline;float:left;width:85%">
              <button class="fbutton" @click="backAccount">Accueil</button>
            </li> 
            <li style="line-height:60px;display:inline;width:50px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>
      <div class="line showmobile" style="height:60px;border-bottom:solid 1px var(--C3);">
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:left;margin-left:15px;">
            <li style="line-height:60px;">
              <img src="media/logo.png" alt="" style="height:50px;margin-top:5px;" @click="backAccount">
            </li>
          </ul>
        </div>
        <div class="line-50">
          <ul style="list-style:none;padding:0px;margin:0px;text-align:right;margin-right:15px;">
            <li style="line-height:60px;">
              <login :account="account"></login>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="viewdiv=='reservation'">
        <div class="line" style="text-align:center;">      
          <h1>Nouvelle réservation</h1>
          <h3>Effectuez votre réservation en ligne</h3>
        </div>
        <div class="line-a" style="margin-top:40px;margin-bottom:40px;">

          <div v-if="step>=1" class="account-bloc">
              <div class="account-bloc-title">
                <label style="font-size:18px;">Etape 1 / {{maxstep}}</label>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Sélectionnez le type de vélo que vous souhaitez louer</p>
                <br>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;text-align:justify;">Les vélos électriques proposés sont de différentes tailles (S ; M ou L). Il est important de choisir un vélo à votre taille afin que l’expérience soit la plus agréable possible. Votre taille doit se trouver dans la fourchette de taille du vélo choisi. Par exemple : si je fais 165cm, je prends un vélo S 155-170cm. 
Les tailles exprimées en centimètres se trouvent entre parenthèse à côté de chaque vélo.</p>
                <br>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;text-align:justify;">Si vous souhaitez louer un siège bébé avec votre vélo, veillez à bien le sélectionner dans la liste (pas de surcoût, mais le siège bébé ne permet pas d’installer une sacoche en plus sur le porte bagage).</p>
                <br>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;text-align:justify;">La livraison du vélo se fait uniquement sur rendez-vous, une fois que les pièces auront été vérifiées et validées par les agents de la CAE.</p>
                <br>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;text-align:justify;">Un mandat de prélèvement devra être rempli au moment de la livraison qui servira de <a style="font-weight:bold">caution</a>.</p>
                <br>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;text-align:justify;"><a style="font-weight:bold">Aucun remboursement</a> ne sera effectué si votre réservation est caduque (hors territoire de la CAE, pour voir si vous êtes bien dans la CAE reportez-vous à la carte disponible : <a href="https://www.agglo-epinal.fr/identite/presentation" target="_blank">https://www.agglo-epinal.fr/identite/presentation</a> ; pièces non valables, fausses informations…).</p>
              </div>
              <ul class="family-bloc-text">
                <li v-for="material in list_materials" :key="material.id" @click="validS1(material)">
                  <div class="family-bloc-title">{{material.label}}</div>
                  <div v-if="material.qty>0">
                    <div class="family-bloc-icon">
                      <v-lazy-image :src="'./media/velo_'+material.id+'.jpg'" :src-placeholder="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;"/>
                      <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;">-->
                    </div>
                    <div v-if="material.est_elec != 'Non' && material.id < 11" class="family-bloc-legend">Taille {{material.size}} ({{material.cm}})</div>
                    <div v-if="material.est_elec == 'Non'" class="family-bloc-legend">Sans assistance électrique</div>
                    <div v-if="material.est_elec != 'Non' && material.id == 11" class="family-bloc-legend">Cargo électrique</div>
                    <div v-if="material.est_elec != 'Non' && material.id == 12" class="family-bloc-legend">Longtail électrique</div>
                  </div>
                  <div v-if="material.qty<=0">
                    <div class="family-bloc-icon">
                      <v-lazy-image :src="'./media/velo_'+material.id+'.jpg'" :src-placeholder="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;filter:opacity(0.3);"/>
                      <!--<img :src="'./media/velo_'+material.id+'.jpg'" alt="" :class="{'border-bloc-selected' : (material.selected)}" style="height:100px;filter:grayscale(1);">-->
                    </div>
                    <div v-if="material.est_elec != 'Non' && material.id < 11" class="family-bloc-legend"><b>Rupture de stock</b> - Taille {{material.size}} ({{material.cm}})</div>
                    <div v-if="material.est_elec == 'Non'" class="family-bloc-legend"><b>Rupture de stock</b> - Sans assistance électrique</div>
                    <div v-if="material.est_elec != 'Non' && material.id == 11" class="family-bloc-legend"><b>Rupture de stock</b> - Cargo électrique</div>
                    <div v-if="material.est_elec != 'Non' && material.id == 12" class="family-bloc-legend"><b>Rupture de stock</b> - Longtail électrique</div>
                  </div>
                  <div class="family-bloc-select"></div>
                </li>
              </ul>
              <div id="blocacc" v-if="JSON.stringify(material_selected)!='{}' && material_selected.accessories.length>0" class="account-bloc-title" style="border-top:solid 1px var(--C3);">
                <label style="font-size:18px;">Accessoires</label>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Sélectionnez les accessoires au nombre de {{material_selected.qtymaxaccessories}} au maximum</p>
              </div>
              <ul v-if="JSON.stringify(material_selected)!='{}' && material_selected.accessories.length>0" class="accessory-bloc-text">
                <li v-for="acc in material_selected.accessories" :key="acc.id">
                  <div class="family-bloc-title">{{acc.label}}</div>
                  <!--<div class="family-bloc-icon"><img :src="'./media/'+acc.logo+'.png'" alt="" style="height:100px;"></div>-->
                  <ul v-if="acc.qties.length>1" class="accessory-bloc-qty">
                    <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc,q)"><label :class="{'border-bloc-selected' : (q.selected==true)}">{{q.value}}</label></li>
                  </ul>
                  <ul v-if="acc.qties.length==1" class="accessory-bloc-qty">
                    <li v-for="q in acc.qties" :key="q.value" @click="validS1acc(acc,q)"><label>Rupture de stock</label></li>
                  </ul>
                </li>
              </ul>
          </div>      
        </div>

        <div id="bloc-nostock" v-if="step>=2 && nostock" class="line-a" style="margin-bottom:40px;">
          <div class="account-bloc" style="text-align:center;fontweight:bold">
            <p style="color:red;font-size:20px;text-align:center;padding:5px;margin:05x;">Attention ! il n'y a plus de stock, vous serez inscrits sur liste d'attente</p>
          </div>
        </div>

        <div id="bloc2" v-if="step>=2" class="line-a" style="margin-bottom:40px;">
          <div class="account-bloc">
              <div class="account-bloc-title">
                <label style="font-size:18px;">Etape 2 / {{maxstep}}</label>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Sélectionnez la date souhaitée de livraison</p>
              </div>
                <div class='form-input' style="margin:0px;padding:0px;margin-left:calc(50% - 160px);width:320px;">
                  <datepicker style='margin-top:0px;padding-left:0px;border:none;margin-bottom:40px' :language="lgdate" format='dd/MM/yyyy' :class="{'finput-form':true,'finput-error':(dateoutofbound!='')}" v-model="date_retrait" :disabled-dates="state.disabledDates" placeholder="Date de retrait" @input='validS2(date_retrait)'></datepicker>
                </div>
              <!--<div class="account-bloc-text">
                <input :class="{'finput-date':true,'finput-error':(dateoutofbound!=true)}" v-model="date_retrait" type="date" placeholder="" ref="date_retrait" :min="date_start_min" :max='date_start_max'  @input="validS2(date_retrait)">
              </div>-->
          </div>      
        </div>

        <div id="bloc3" v-if="step>=3" class="line-a" style="margin-bottom:40px;">
          <div class="account-bloc">
              <div class="account-bloc-title">
                <label style="font-size:18px;">Etape 3 / {{maxstep}}</label>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Sélectionnez le type d'abonnement</p>
              </div>
              <div v-for="subscription in material_selected.subscriptions" v-bind:key="subscription.id" :class="{'account-bloc-text':true,'bloc-selected' : (subscription.selected==true)}" @click="validS3(subscription)">
                <div class="account-bloc-legend">ABONNEMENT {{subscription.label}}</div>
                <div class="account-bloc-value" style="font-size:12px;color:var(--C2);">Durée de {{subscription.duration}} {{subscription.type_duration}}</div>
                <div class="account-bloc-icon">
                  <fa-icon v-if="!(subscription.selected==true)" :icon="['far','circle']" />
                  <fa-icon v-if="(subscription.selected==true)" :icon="['far','check-circle']" />
                </div>
              </div>
          </div>      
        </div>

        <div id="bloc4" v-if="step>=4" class="line-a" style="margin-bottom:40px;">
          <div class="account-bloc">
            <div class="account-bloc-title">
            <label style="font-size:18px;">Etape 4 / {{maxstep}}</label>
            <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Sélectionnez le type de règlement</p>
            </div>
            <div v-if="!nostock">
              <div v-for="pric in subscription_selected.prices"  :key="pric.id" :class="{'account-bloc-text':true,'bloc-selected' : (pric.selected==true)}" @click="validS4(pric)">
              <div class="account-bloc-legend">{{pric.label}}</div>
              <div class="account-bloc-value" style="font-size:12px;color:var(--C2);">{{pric.description}}</div>
              <div class="account-bloc-icon" style="text-align:left;padding:20px 20px 20px 0px;">
                <label v-if="pric.type>=4" style="font-weight:bold;">Payez maintenant : {{list_prices[1].amount|money}}</label>
                <label v-if="pric.type<=3" style="font-weight:bold;">Payez maintenant : {{list_prices[0].amount|money}}</label>
                <br>
                <label style="font-size:13px;">Prix total : {{list_prices[0].total_amount|money}}</label>
              </div>
              </div>
            </div>
            <div v-if="nostock" :key="3" :class="{'account-bloc-text':true,'bloc-selected' : (subscription_selected.prices[0].selected==true)}" @click="validS4(subscription_selected.prices[0])">
              <div class="account-bloc-legend">ABONNEMENT MENSUEL</div>
              <div class="account-bloc-value" style="font-size:12px;color:var(--C2);">Le prix indiqué peut changer selon la date de la réservation, il vous sera reprécisé plus tard</div>
              <div class="account-bloc-icon" style="text-align:left;padding:20px 20px 20px 0px;">
                <label  style="font-weight:bold;">Prix estimé : {{list_prices[0].total_amount|money}}</label>
              </div>
            </div>
          </div>      
        </div>
        <div id="bloc5" v-if="step>=5" class="line-a" style="margin-bottom:40px;">
          <div class="account-bloc">
              <div class="account-bloc-title">
                <label style="font-size:18px;">Etape 5 / {{maxstep}}</label>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Insérer les documents adminitratifs nécessaires pour la location</p>
              </div>
              <div v-if="subscription_selected.id>=1">
                <div class="account-bloc-text" @click="editprofile">
                  <div class="account-bloc-legend">IBAN / BIC</div>
                  <div v-if="account.iban=='' || account.bic==''" style="color:red;font-weight:bold;" class="account-bloc-value">Veuillez renseigner votre IBAN et BIC</div>
                  <div v-if="account.iban!='' && account.bic!=''" class="account-bloc-value">{{account.iban}} / {{account.bic}}</div>
                  <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
                </div>
                <div v-if="piece_cni==true" class="account-bloc-text">
                  <div class="account-bloc-legend">CNI / Passeport en cours de validité</div>
                  <div class="account-bloc-value" @click="downloadFile('cni')">Consulter le document</div>
                  <div class="account-bloc-icon"  @click="uploadFile('cni')"><i class='fa fa-upload'></i></div>
                </div>
                <div v-if="piece_cni==false" class="account-bloc-text" @click="uploadFile('cni')">
                  <div class="account-bloc-legend">CNI / Passeport en cours de validité</div>
                  <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
                  <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
                </div>
                <div v-if="piece_justificatif==true" class="account-bloc-text">
                  <div class="account-bloc-legend">Justificatif de domicile de moins de 3 mois</div>
                  <div class="account-bloc-value" @click="downloadFile('justificatif')">Consulter le document</div>
                  <div class="account-bloc-icon"  @click="uploadFile('justificatif')"><i class='fa fa-upload'></i></div>
                </div>
                <div v-if="piece_justificatif==false" class="account-bloc-text" @click="uploadFile('justificatif')">
                  <div class="account-bloc-legend">Justificatif de domicile de moins de 3 mois</div>
                  <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
                  <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
                </div>
                <div v-if="piece_rib==true" class="account-bloc-text">
                  <div class="account-bloc-legend">RIB</div>
                  <div class="account-bloc-value" @click="downloadFile('rib')">Consulter le document</div>
                  <div class="account-bloc-icon"  @click="uploadFile('rib')"><i class='fa fa-upload'></i></div>
                </div>
                <div v-if="piece_rib==false" class="account-bloc-text" @click="uploadFile('rib')">
                  <div class="account-bloc-legend">RIB</div>
                  <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Document manquant</div>
                  <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
                </div>
              </div>

          </div>      
        </div>
        <div id="bloc6" v-if="step>=5 && account.is_main_bank=='non'" class="line-a" style="margin-bottom:40px;">
          <div class="account-bloc">
            <div class="account-bloc-title">
              <label style="font-size:18px;">Informations concernant le titulaire du compte bancaire</label>
              <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Fournir les informations complémentaires concernant le titulaire du compte</p>
            </div>
            <div v-if="banq_account.nom==''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div style="color:red;font-weight:bold;" class="account-bloc-value"><i class='fa fa-info-circle'></i> Veuillez renseigner les informations du titulaire du compte</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="banq_account.nom!=''" class="account-bloc-text" @click="editbanqaccount">
              <div class="account-bloc-legend">TITULAIRE DU COMPTE</div>
              <div class="account-bloc-value">{{banq_account.prenom}} {{banq_account.nom}}</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="piece_cni_titulaire==true" class="account-bloc-text">
              <div class="account-bloc-legend">CNI / Passeport en cours de validité</div>
              <div class="account-bloc-value" @click="downloadFile('cni_titulaire')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="uploadFile('cni_titulaire')"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_cni_titulaire==false" class="account-bloc-text" @click="uploadFile('cni_titulaire')">
              <div class="account-bloc-legend">CNI / Passeport en cours de validité</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Insérer la CNI ou le passeport du titulaire du compte bancaire</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div v-if="piece_attestation==true" class="account-bloc-text">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" @click="downloadFile('attestation')">Consulter le document</div>
              <div class="account-bloc-icon"  @click="editbanqfile"><i class='fa fa-upload'></i></div>
            </div>
            <div v-if="piece_attestation==false" class="account-bloc-text" @click="editbanqfile">
              <div class="account-bloc-legend">Procuration</div>
              <div class="account-bloc-value" style="color:red;font-weight:bold;"><i class='fa fa-info-circle'></i> Insérer le formulaire de procuration complété et signé</div>
              <div class="account-bloc-icon"><i class='fa fa-chevron-right'></i></div>
            </div>
          </div>      
        </div>
        <div id="bloc_recap" v-if="visu_payment" class="line-a" style="margin-bottom:40px;padding-bottom:40px;">
          <div class="account-bloc">
              <div class="recap-bloc-title">
                <label style="font-size:18px;">Récaptitulatif</label>
                <p style="color:var(--C2);font-size:13px;padding:0px;margin:0px;">Récapitulatif de votre réservation</p>
              </div>
              <div class="recap-bloc-title" style="background-color:var(--BUTTON);border-bottom:none;">
                <label style="font-size:18px;color:#fff;"><i class='fa fa-exclamation'></i></label>
                <p style="color:#fff;font-size:13px;padding:0px;margin:0px;">La livraison du vélo se fait uniquement sur rendez-vous, une fois que les pièces auront été vérifiées et validées par les agents de la CAE.</p>
                <br>
                <p style="color:#fff;font-size:13px;padding:0px;margin:0px;">Un mandat de prélèvement devra être rempli au moment de la livraison qui servira de <a style="font-weight:bold;color:white">caution</a>.</p>
                <br>
                <p style="color:#fff;font-size:13px;padding:0px;margin:0px;"><a style="font-weight:bold;color:white">Aucun remboursement</a> ne sera effectué si votre réservation est caduque (hors territoire de la CAE, pour voir si vous êtes bien dans la CAE reportez-vous à la carte disponible : <a href="https://www.agglo-epinal.fr/identite/presentation" target="_blank" style="color:white">https://www.agglo-epinal.fr/identite/presentation</a> ; pièces non valables, fausses informations…). </p>
                <!--<p style="color:#fff;font-size:13px;padding:0px;margin:0px;">Le retrait du vélo est uniquement sur RDV. Une fois la réservation effectuée,<br/>Vous serez contactez par nos services.</p>-->
                <br>
                <p style="color:darkred;font-size:13px;padding:0px;margin:0px;"><a style="font-weight:bold;color:red">ATTENTION !</a></p>
                <p style="color:darkred;font-size:13px;padding:0px;margin:0px;">Si vous utilisez le navigateur <a style="font-weight:bold;color:red">Chrome</a>, l’apparition éventuelle d’une page d’erreur pourrait apparaitre après votre paiement. Votre paiement aura tout de même été validé, vous pourrez le vérifier via le mail de confirmation que avez allez recevoir.</p>
              </div>
              <div class="recap-bloc-text" style="background-color:var(--C2);color:#fff;border-top:none;">
                <div style="padding:20px;text-align:center;width:calc(100% - 40px);float:left;">
                  <label style="font-size:16px;">{{lbl_description}}</label>
                  <br/>
                  <label v-if="!nostock" style="font-size:32px;">{{lbl_amount|money}}</label>
                  <label v-if="nostock" style="font-size:32px;">{{lbl_amount|money}}</label><br/>
                  <label v-if="!nostock" style="font-size:12px;">{{lbl_payment_info_cb}}</label><br/>
                  <label style="font-size:12px;">la caution s'élèvera a {{lbl_caution_amount|money}}</label>
                  <!--<br/>
                  <label style="font-size:16px;">Abonnement {{subscription_selected.label}}</label>-->
                </div>
              </div>
              <div class="recap-bloc-text" style="background-color:#fff;color:var(--C2);border-top:none;" >
                <div style="padding:20px;text-align:center;width:calc(100% - 40px);float:left;">
                  <label v-if="acceptcguv && material_selected.id!=8" style="font-size:16px;">
                    <fa-icon :icon="['fas','check-square']" @click="acceptcguv=(!acceptcguv)"/>
                    J'accepte les <a style="text-decoration: underline;color:var(--BUTTON);font-weight:bold;cursor:pointer"  @click="downloadFile('CGUV_VAE')">CGUV</a> et la <a target="_blank"  style="color:var(--BUTTON);font-weight:bold;text-decoration: underline;cursor:pointer" @click="downloadFile('RGPD')">politique de confidentialité</a>
                  </label>
                  <label v-if="acceptcguv && material_selected.id==8" style="font-size:16px;">
                    <fa-icon :icon="['fas','check-square']" @click="acceptcguv=(!acceptcguv)"/>
                    J'accepte les <a style="text-decoration: underline;color:var(--BUTTON);font-weight:bold;cursor:pointer"  @click="downloadFile('CGUV_Classique')">CGUV</a> et la <a target="_blank"  style="color:var(--BUTTON);font-weight:bold;text-decoration: underline;cursor:pointer" @click="downloadFile('RGPD')">politique de confidentialité</a>
                  </label>
                  <label v-if="!acceptcguv && material_selected.id!=8" style="font-size:16px;">
                    <fa-icon :icon="['fas','square']" @click="acceptcguv=(!acceptcguv)"/>
                    J'accepte les <a target="_blank"  style="text-decoration: underline;color:var(--BUTTON);font-weight:bold;cursor:pointer" @click="downloadFile('CGUV_VAE')">CGUV</a> et la <a target="_blank"  style="color:var(--BUTTON);font-weight:bold;text-decoration: underline;cursor:pointer" @click="downloadFile('RGPD')">politique de confidentialité</a>
                  </label>
                  <label v-if="!acceptcguv && material_selected.id==8" style="font-size:16px;">
                    <fa-icon :icon="['fas','square']" @click="acceptcguv=(!acceptcguv)"/>
                    J'accepte les <a target="_blank"  style="text-decoration: underline;color:var(--BUTTON);font-weight:bold;cursor:pointer;" @click="downloadFile('CGUV_Classique')">CGUV</a> et la <a target="_blank"  style="color:var(--BUTTON);font-weight:bold;text-decoration: underline;cursor:pointer" @click="downloadFile('RGPD')">politique de confidentialité</a>
                  </label>
                </div>
              </div>              
              <div class="recap-bloc-text" style="padding-top:20px;padding-bottom:20px;text-align:center;width:100%;">
                <div style="padding:10px;text-align:center;width:calc(100% - 20px);float:left;">
                  <button v-if="acceptcguv && !nostock" @click="payment_start" class="fbutton fbutton-big">Régler la réservation</button>
                  <button v-if="!acceptcguv && !nostock" class="fbutton fbutton-big" style="background-color:var(--C2);">Régler la réservation</button>
                  <button v-if="acceptcguv && nostock" @click="goNOSTOCK" class="fbutton fbutton-big">S'inscrire sur liste d'attente</button>
                  <button v-if="!acceptcguv && nostock" class="fbutton fbutton-big" style="background-color:var(--C2);">S'inscrire sur liste d'attente</button>

                </div>
              </div>
          </div>      
        </div>
      </div>
      <div v-if="viewdiv=='retour'">
        <div class="line" style="text-align:center;margin-top:120px;">  
          <i v-if="msgretour=='effectue'" style="color:var(--BUTTON);" class='far fa-smile-beam fa-4x'></i>    
          <i v-if="msgretour!='effectue'" style="color:var(--BUTTON);" class='far fa-frown fa-4x'></i>    
          <h1>{{msgtitle}}</h1>
          <h3>{{msgsubtitle}}</h3>
          <button class='fbutton' @click="backAccount">Retour à votre compte</button>
        </div>
      </div>
      <div v-if="viewdiv=='nostock'">
        <div class="line" style="text-align:center;margin-top:120px;">  
          <i style="color:var(--BUTTON);" class='far fa-frown fa-4x'></i>    
          <h1>Rupture de stock</h1>
          <h3>Nous n'avons plus de vélo électrique de disponible, veuillez cliquer sur le bouton ci-dessous afin de vous inscrire dans notre liste d'attente.</h3>
          <button style="margin-right:10px;" class='fbutton' @click="goNOSTOCK">S'inscrire sur la liste d'attente</button>
          <button class='fbutton' @click="backAccount">Retour à votre compte</button>
        </div>
      </div>
      <div v-if="viewdiv=='nostock_valid'">
        <div class="line" style="text-align:center;margin-top:120px;">  
          <i style="color:var(--BUTTON);" class='far fa-smile-beam fa-4x'></i>    
          <h1>Inscription dans la liste d'attente</h1>
          <h3>Votre inscription dans la liste d'attente a bien été effectuée.</h3>
          <button class='fbutton' @click="backAccount">Retour à votre compte</button>
        </div>
      </div>
    </div>
    <dataform v-if="JSON.stringify(ctrldataform)!='{}'" :title="ctrldataform.title" :controls="ctrldataform.controls" :fxback="ctrldataform.fxback" :fxsave="ctrldataform.fxsave"></dataform>
    <loadingform v-if="loading"></loadingform>
  </div>
</template>

<script>
import engine from "../core/DataAPI";
import login from "../core/IconLogin";
import dataform from "../core/DataForm";
import datepicker from "vuejs-datepicker";
import loadingform from "../core/LoadingForm";
import {fr} from 'vuejs-datepicker/dist/locale';

export default {
  components:{
    login,dataform,loadingform,datepicker
  },
  data() {
    return {
      state:{
        disabledDates:{},
      },
      loading:false,
      lgdate:fr,
      debut_haute:"03-21",
      debut_basse:"09-21",
      acceptcguv:false,
      msgtitle:'',
      msgsubtitle:'',
      msgretour:'',
      account:{},
      vdate:'',
      datenow:'',
      viewdiv:'reservation',
      maxstep:5,
      currentstep:1,
      piece_cni:false,
      piece_justificatif:false,
      piece_rib:false,
      /*piece_cmuc:false,*/
      /*piece_mobilite:false,*/
      piece_attestation:false,
      piece_cni_titulaire:false,
      date_retrait:'',
      date_fin:'',
      mois_2:0,
      mois_3:0,
      step:1,
      nostock:false,
      list_materials:[],
      list_agencies:[],
      list_prices:[],
      ctrldataform: {},
      banq_account:{nom:'',prenom:'',adresse:'',cp:'',ville:'',email:'',telephone:'',lien_parente:'',id_fichier:engine.Guid()}
    }
  },
  computed: {
    visu_payment: function(){
      var tmain = this;
      var ret = false;

      if (tmain.step>=5){
        if (tmain.subscription_selected.id==1){
          if (tmain.piece_cni && tmain.piece_justificatif && tmain.piece_rib && (tmain.account.iban!='') && (tmain.account.bic!='')){
            if (tmain.account.is_main_bank=='oui'){
              ret = true;
            }else{
              if (tmain.banq_account.nom!='' && tmain.piece_attestation && tmain.piece_cni_titulaire){
                ret = true;
              }
            }            
          }
        }
        if (tmain.subscription_selected.id==2){
          if (tmain.piece_cni && tmain.piece_justificatif && tmain.piece_rib && (tmain.account.iban!='') && (tmain.account.bic!='')){
            if (tmain.account.is_main_bank=='oui'){
              ret = true;
            }else{
              if (tmain.banq_account.nom!='' && tmain.piece_attestation && tmain.piece_cni_titulaire){
                ret = true;
              }
            }            
          }
        }
        if (tmain.subscription_selected.id==3){
          if (tmain.piece_cni && tmain.piece_justificatif && tmain.piece_rib && (tmain.account.iban!='') && (tmain.account.bic!='')){
            if (tmain.account.is_main_bank=='oui'){
              ret = true;
            }else{
              if (tmain.banq_account.nom!='' && tmain.piece_attestation && tmain.piece_cni_titulaire){
                ret = true;
              }
            }            
          }
        }
      }
      return ret;
    },
    material_selected: function(){
      var tmain = this;
      var info = tmain.list_materials.find(x=>x.selected==true);
      if (info==undefined){
        info = {};
      }
      return info;
    },
    subscription_selected: function(){
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find(x=>x.selected==true);
      if (mat!==undefined){
        var infret = mat.subscriptions.find(x=>x.selected==true);
        if (infret !== undefined){
          ret = infret;
        }
      }
      return ret;
    },
    price_selected: function(){
      var tmain = this;
      var ret = {};
      var mat = tmain.list_materials.find(x=>x.selected==true);
      if (mat!==undefined){
        var infsub = mat.subscriptions.find(x=>x.selected==true);
        if (infsub !== undefined){
          var infprice = infsub.prices.find(x=>x.selected==true);
          if (infprice !== undefined){
            ret = infprice;
          }
        }
      }
      return ret;
    },
    agency_selected: function(){
      var tmain = this;
      var info = tmain.list_agencies.find(x=>x.selected==true);
      if (info==undefined){
        info = {};
      }
      return info;
    },
    lbl_description: function(){
      
      var tmain = this;
      var ret = "";
      var mat = tmain.list_materials.find(x=>x.selected==true);
      if (mat!==undefined){
        ret += "1 x " + mat.label + " - ";
        mat.accessories.forEach(function(acc){
          if (acc.qties.find(x=>x.selected==true).value>0){
            ret += acc.qties.find(x=>x.selected==true).value + " x " + acc.label + " - ";
          }          
        });
      }
      return ret.substring(0,ret.length-3);
    },
    lbl_amount: function(){      
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find(x=>x.selected==true);
      if (mat!==undefined){
        var infsub = mat.subscriptions.find(x=>x.selected==true);
        if (infsub !== undefined){
          var infprice = infsub.prices.find(x=>x.selected==true);
         if (infprice !== undefined){
            if(tmain.nostock){
              ret = infprice.total_amount;
            }else{
              ret += infprice.amount;
            }
          }
        }
      }
      return ret;
    },
    lbl_caution_amount: function(){
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find(x=>x.selected==true);
      if (mat!==undefined){
       ret =  mat.caution;
      }
      return ret;
    },
    lbl_amount_year: function(){
      var tmain = this;
      var ret = 0;
      var mat = tmain.list_materials.find(x=>x.selected==true);
      if (mat!==undefined){
        var infsub = mat.subscriptions.find(x=>x.selected==true);
        if (infsub !== undefined){
          var infprice = infsub.prices.find(x=>x.selected==true);
          if (infprice !== undefined){
            ret += infprice.amount;
          }
        }
      }
      return (ret*11);
    },
    lbl_payment_info_cb: function(){
      var tmain = this;
      var lbl = "";
      var formatter = new Intl.NumberFormat('fr-FR', {style: 'currency',currency: 'EUR',minimumFractionDigits: 2});
      switch(tmain.price_selected.type){
        case 1:
          lbl = formatter.format(tmain.lbl_amount) + ' à régler maintenant';
          break;
        case 2:
          lbl = formatter.format(tmain.lbl_amount) + ' à régler maintenant, restant dû le jour de la réservation 55€ par chèques de 5€.';
          break;
        case 3:
          lbl = formatter.format(tmain.lbl_amount) + ' à régler maintenant, restant dû le jour de la réservation ';
          lbl += formatter.format(tmain.mois_2) + ' et ';
          lbl += formatter.format(tmain.mois_3) + ' par chèques, pour les mois suivants.';
          break;
        case 4:
          lbl = formatter.format(tmain.lbl_amount) + ' à régler maintenant';
          break;
      }
      return lbl;
    },
    date_start_min: function() {
      var tmain = this;
      var datemin = new Date();
      datemin.setDate(datemin.getDate() + 7);
      return tmain.$moment(datemin).format('YYYY-MM-DD');
    },
    date_start_max: function() {
      var tmain = this;
      var datemax = new Date();
      datemax.setDate(datemax.getDate() + 30);
      return tmain.$moment(datemax).format('YYYY-MM-DD');
    },
    dateoutofbound: function() {
      
      return false;
      // var tmain = this;
      // var today = new Date();
      // today =
      //   today.getMonth() +
      //   1 +
      //   "/" +
      //   String(today.getDate()).padStart(2, "0") +
      //   "/" +
      //   today.getFullYear();
      // if (
      //   (tmain.$moment(tmain.date_retrait).format("YYYY-MM-DD").diff(tmain.$moment(today), "days") < 7 &&
      //   tmain.date_retrait != "") ||
      //   (tmain.$moment(tmain.date_retrait).format("YYYY-MM-DD").diff(tmain.$moment(today), "days") > 30 &&
      //   tmain.date_retrait != "")
      // ) {
      //   return false;
      // }
      // return true;
    },
    date_end: function() {
      /*
      var tmain = this;
      var dateend = new Date();
      var mat = tmain.list_materials.find(x=>x.selected==true);
      var sub = mat.subsriptions.find(x=>x.selected==true);
      var duration = sub.type_duration == "mois" ? sub.duration : 12;
      dateend.setMonth(dateend.getMonth() + duration);
      */
      var tmain = this;
      var dateend = new Date();
      return tmain.$moment(dateend);
    },
  },
  mounted: function(){
    this.$moment.locale('fr');
  },
  created: function(){
    var tmain = this;

    tmain.datenow = tmain.$moment().format("YYYY-MM-DD");
    if (JSON.stringify(tmain.$route.query) != '{}'){
      tmain.refresh_ret(tmain.$route.query);
    }else{
      tmain.refresh_ui();
    }
  },
  methods: {
    refresh_ret: function(params){
      var tmain = this;
      tmain.loading=true;
      tmain.$router.replace({'query':null});
      tmain.viewdiv = 'retour';
      tmain.msgretour = params.state;
      engine.Account_Info(function(pkg){
        if (pkg.code==-1){
          tmain.$router.push('/');
        }else{
          tmain.account = pkg.data[0];
          engine.Agency_List(function(pkga){
            tmain.list_agencies = pkga.data;
            engine.Material_List(function(pkgm){
              tmain.list_materials = pkgm.data;
              engine.File_Exist('cni',function(pkgp1){
                tmain.piece_cni = pkgp1.data;
                engine.File_Exist('justificatif',function(pkgp2){
                  tmain.piece_justificatif = pkgp2.data;
                  engine.File_Exist('rib',function(pkgp3){
                    tmain.piece_rib = pkgp3.data;

                        switch(params.state){
                          case "effectue":

                              if (params.resa_number){
                                tmain.msgtitle = 'Votre réservation ' + params.resa_number+' a été effectuée avec succès.';
                                tmain.msgsubtitle = 'Vous allez être contacté par notre équipe pour convenir d\'un rendez-vous.';
                                tmain.loading=false;                                                      
                              }else{
                                tmain.msgtitle = 'Votre réservation ' + params.resa_number+' a rencontré une erreur.';
                                tmain.msgsubtitle = 'Veuillez contacter la Vilvolt par e-mail au vilvolt@agglo-epinal.fr.';
                                tmain.loading=false;
                              }   
                   
                            break;
                          case "annule":
                            engine.Reservation_Cancel(params.Ref,function(){
                              tmain.msgtitle = 'Annulation';
                              tmain.msgsubtitle = 'Vous avez annulé votre demande de réservation de vélo.';
                              tmain.loading=false;
                            });          
                            break;
                          case "refuse":
                            tmain.msgtitle = 'Votre réservation ' + params.Ref+' a rencontré une erreur de paiement.';
                            tmain.msgsubtitle = 'Veuillez contacter la Vilvolt par e-mail au vilvolt@agglo-epinal.fr.';
                            tmain.loading=false;
                            break;
                        }
                      // });
                    // });
                  });
                });
              });
              
            });
          });            
        }
      });
       
      
      
    },
    refresh_ui: function(){
      var tmain = this;
      tmain.loading=true;
      engine.Account_Info(function(pkg){
        if (pkg.code!=0){
          tmain.$router.push('/');
        }else{
          tmain.account = pkg.data[0];
          engine.Agency_List(function(pkga){
            tmain.list_agencies = pkga.data;
            engine.Material_List(function(pkgm){
              tmain.list_materials = pkgm.data;
              tmain.loading=false;
              var total_quantity = 0;
              tmain.list_materials.forEach(function(material){
                total_quantity += material.qty;
              })
              if (total_quantity==0){
                tmain.viewdiv = "nostock";
              }
            });
          });              
        }
        engine.File_Exist('cni',function(pkgp1){
          tmain.piece_cni = pkgp1.data;
        });
        engine.File_Exist('justificatif',function(pkgp2){
          tmain.piece_justificatif = pkgp2.data;
        });
        engine.File_Exist('rib',function(pkgp3){
          tmain.piece_rib = pkgp3.data;
        });
        /*engine.File_Exist('cmuc',function(pkgp4){
          tmain.piece_cmuc = pkgp4.data;
        });
        engine.File_Exist('passmobilite',function(pkgp5){
          tmain.piece_mobilite = pkgp5.data;
        });*/
      });
    },
    refresh_docs: function(){
      var tmain = this;
      engine.File_Exist('cni',function(pkgp1){
        tmain.piece_cni = pkgp1.data;
        engine.File_Exist('cni_titulaire',function(pkgp12){
          tmain.piece_cni_titulaire = pkgp12.data;
          engine.File_Exist('justificatif',function(pkgp2){
            tmain.piece_justificatif = pkgp2.data;
            engine.File_Exist('rib',function(pkgp3){
              tmain.piece_rib = pkgp3.data;
              /*engine.File_Exist('cmuc',function(pkgp4){
                tmain.piece_cmuc = pkgp4.data;
                engine.File_Exist('passmobilite',function(pkgp5){
                  tmain.piece_mobilite = pkgp5.data;*/
                  engine.File_Exist(tmain.banq_account.id_fichier,function(pkgp6){
                    tmain.piece_attestation = pkgp6.data;
                    var options = {
                      container: '.application',
                      easing: 'ease-out',
                      offset: -10,
                      force: true,
                      cancelable: true,
                      x: false,
                      y: true
                    };
                    if (tmain.account.is_main_bank=='non'){
                      if (tmain.piece_attestation && tmain.piece_cni_titulaire){
                        setTimeout(function(){tmain.$scrollTo("#bloc_recap",500,options);},100);
                      }else{
                        setTimeout(function(){tmain.$scrollTo("#bloc6",500,options);},100);
                      }                      
                    }else{
                      setTimeout(function(){tmain.$scrollTo("#bloc_recap",500,options);},100);
                    }
                  /*});
                });*/
              });
            });
          });
        });
      });
    },
    validS1: function(mat){
      var tmain = this;   
      tmain.date_retrait = "";
      tmain.acceptcguv = false;
      if (mat.qty<=0){
        tmain.nostock = true;
      }else{
        tmain.nostock = false;
      }
        tmain.list_materials.forEach(mat=> {
          mat.selected=false;
          mat.accessories.forEach(acc=> {
            acc.selected=false;
            acc.qties.forEach(q=>q.selected=false);
            acc.qties[0].selected=true;
          });
        });
        tmain.qtycumul=0;
        mat.selected = true;
        var options = {
          container: '.application',
          easing: 'ease-out',
          offset: -10,
          force: true,
          cancelable: true,
          x: false,
          y: true
        };
        // if (mat.id==2){
        var newDateMin = new Date();
        var newDateMax = new Date();
        var jmin = newDateMin.getDate() + 7;
        var jmax = newDateMax.getDate() + 30;
        var mmin = newDateMin.getMonth();
        var mmax = newDateMax.getMonth();
        var amin = newDateMin.getFullYear();
        var amax = newDateMax.getFullYear();
        tmain.state.disabledDates = {
          to: new Date(amin,mmin,jmin),
          from: new Date(amax,mmax,jmax)
        }
          tmain.step = 2;        
          setTimeout(function(){
            tmain.$scrollTo("#bloc2",500,options);
          },100);
        // }else{
        //   tmain.step = 2;
        //   setTimeout(function(){
        //     tmain.$scrollTo("#blocacc",500,options);
        //   },100);
        // } 
      //}   
    },
    validS1acc: function(acc,qtyselect){
      var tmain = this; 
      tmain.date_retrait = "";
      acc.qties.forEach(x=>x.selected=false);
      
      acc.selected=true;
      qtyselect.selected = true;     
      var qtycumul = 0;
      tmain.material_selected.accessories.filter(x=>x.selected==true).forEach(function(tacc){
        tacc.qties.filter(q=>q.selected==true).forEach(function(qt) {
          qtycumul += qt.value;
        });
      });

      if (tmain.material_selected.accessories.filter(x=>x.selected==true).length==tmain.material_selected.accessories.length){
        if (qtycumul > tmain.material_selected.qtymaxaccessories){
          qtyselect.selected=false;
          tmain.material_selected.accessories.filter(x=>x.selected==true).forEach(function(tacc){
            if (tacc.qties.filter(q=>q.selected==true).length==0) {
              tacc.selected=false;
              tmain.step=1;
            }
          });
          alert("trop de qte selectionné");          
        }else{
          tmain.step = 2;
          var options = {
            container: '.application',
            easing: 'ease-out',
            offset: -10,
            force: true,
            cancelable: true,
            x: false,
            y: true
          };
          setTimeout(function(){
            tmain.$scrollTo("#bloc2",500,options);
          },100);
        }
      }
    },
    validS2: function(date_retrait){
      var tmain = this;
      tmain.$moment(date_retrait).format('YYYY-MM-DD');
      tmain.date_retrait = tmain.$moment(date_retrait).format('YYYY-MM-DD');
      tmain.material_selected.subscriptions.forEach(s=>s.selected=false);
      if(tmain.$moment(date_retrait) <= tmain.$moment(tmain.date_start_max) && tmain.$moment(date_retrait) >= tmain.$moment(tmain.date_start_min)){
        tmain.step = 3;
        var options = {
          container: '.application',
          easing: 'ease-out',
          offset: -10,
          force: true,
          cancelable: true,
          x: false,
          y: true
        };
        setTimeout(function(){
          tmain.$scrollTo("#bloc3",500,options);
        },100);
      }else{
        tmain.step = 2;
      }
    },
    validS3: function(subs){
      var tmain = this;
      tmain.material_selected.subscriptions.forEach(s=>s.selected=false);
      subs.selected=true;
      subs.prices.forEach(s=>s.selected=false);
      var i;
      var tot = [];
      var tot_2 = [];
      for (i = 0; i < subs.duration; i++) {
        if(i==0){
          let s = tmain.saison(tmain.date_retrait);
          if(s=="haute"){
            tot[0]=subs.tarif_haute;
            tot[1]=0;
            tot[2]=subs.tarif_haute;
            tot_2[i]=subs.tarif_haute;
          }else{
            tot[0]=subs.tarif_basse;
            tot[1]=0;
            tot[2]=subs.tarif_basse;
            tot_2[i]=subs.tarif_basse;
          }
        }else{
          let splitdate = tmain.date_retrait.split("-");
          if(Number(splitdate[1])+i <= 12){
            let s = tmain.saison(splitdate[0]+"-"+(Number(splitdate[1])+i)+"-"+splitdate[2]);
            if(s=="haute"){
              tot[1]+=subs.tarif_haute;
              tot[2]+=subs.tarif_haute;
              tot_2[i]=subs.tarif_haute;
            }else{
              tot[1]+=subs.tarif_basse;
              tot[2]+=subs.tarif_basse;
              tot_2[i]=subs.tarif_basse;
            }
          }else{
            let s = tmain.saison((Number(splitdate[0])+1)+"-"+(Number(splitdate[1])+i-12)+"-"+splitdate[2]);
            if(s=="haute"){
              tot[1]+=subs.tarif_haute;
              tot[2]+=subs.tarif_haute;
              tot_2[i]=subs.tarif_haute;
            }else{
              tot[1]+=subs.tarif_basse;
              tot[2]+=subs.tarif_basse;
              tot_2[i]=subs.tarif_basse;
            }
          }
        }
      }
      if(subs.type_duration == "an"){
        subs.prices[0].total_amount = tot[2];
        subs.prices[0].amount = tot[2];
        tmain.date_fin = tmain.$moment(tmain.date_retrait).add(1,'year').format('YYYY-MM-DD')
      }else{
        if(subs.duration == 12){
          subs.prices[0].total_amount = tot[2];
          subs.prices[0].amount = tot[0];
        tmain.date_fin = tmain.$moment(tmain.date_retrait).add(1,'year').format('YYYY-MM-DD')
        }else{
          subs.prices[0].total_amount = tot[2];
          subs.prices[0].amount = tot_2[0];
          subs.prices[0].amount_2 = tot_2[1];
          subs.prices[0].amount_3 = tot_2[2];
          subs.prices[0].description = "Paiement de la 1ère mensualité de "+tot_2[0]+"€ en ligne et dépot de deux chèques de "+tot_2[1]+"€ et de "+tot_2[2]+"€ pour les mensualités suivantes au retrait du vélo";
          subs.prices[1].total_amount = tot[2];
          subs.prices[1].amount = tot[2];
          tmain.date_fin = tmain.$moment(tmain.date_retrait).add(3,'month').format('YYYY-MM-DD')
        }
      }
      
      tmain.step = 4;
      tmain.list_prices = [];
      tmain.list_prices = subs.prices;
      var options = {
        container: '.application',
        easing: 'ease-out',
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true
      };
      setTimeout(function(){
        tmain.$scrollTo("#bloc4",500,options);
      },100);
    },
    validS4: function(p){
      var tmain = this;
      tmain.subscription_selected.prices.forEach(s=>s.selected=false);
      p.selected=true;
      if(p.id==3){
        tmain.mois_2 = p.amount_2;
        tmain.mois_3 = p.amount_3;
      }else{
        tmain.mois_2 = 0;
        tmain.mois_3 = 0;
      }
      tmain.step = 5;
      var options = {
        container: '.application',
        easing: 'ease-out',
        offset: -10,
        force: true,
        cancelable: true,
        x: false,
        y: true
      };
      setTimeout(function(){
        tmain.$scrollTo("#bloc5",500,options);
      },100);
    },
    editprofile: function(){
      var tmain = this;
      tmain.ctrldataform = { 
        title:"Modifier vos informations personnelles",
        controls:[
          {datatype:'text',datavalue:tmain.account.iban,databind:'iban',description:'IBAN (la longueur doit être de 27 caractères)'},
          {datatype:'text',datavalue:tmain.account.bic,databind:'bic',description:'BIC'},
          {datatype:'select',datavalue:tmain.account.is_main_bank,databind:'is_main_bank',description:'Etes-vous titulaire du compte bancaire ?',datalist:[{value:'oui',label:'Oui'},{value:'non',label:'Non'}]},
        ],
        fxback:function(){
          tmain.ctrldataform={};
        },
        fxsave:function(o){
          engine.Account_Edit_Bank(o,function(){
            engine.Account_Info(function(pkg){
              if (pkg.code==-1){
                tmain.$router.push('/');
              }else{
                tmain.account = pkg.data[0];
                tmain.refresh_docs();
              }
            });
          });
          tmain.ctrldataform={};
        }
      };
    },
    editbanqaccount: function(){
      var tmain = this;
      tmain.ctrldataform = { 
        title:"Indiquer les informations du titulaire du compte bancaire",
        controls:[
          {datatype:'text',datavalue:tmain.banq_account.prenom,databind:'prenom',description:'Prénom *',condvalue:false,condmsg:'Le prénom est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.nom,databind:'nom',description:'Nom de famille *',condvalue:false,condmsg:'Le nom de famille est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.adresse,databind:'adresse',description:'Adresse *',condvalue:false,condmsg:'L\'adresse est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.cp,databind:'cp',description:'Code postal *',condvalue:false,condmsg:'Le code postal est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.ville,databind:'ville',description:'Ville *',condvalue:false,condmsg:'La ville est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.telephone,databind:'telephone',description:'Téléphone fixe *',condvalue:false,condmsg:'Le numéro de téléphone est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.email,databind:'email',description:'Courriel *',condvalue:false,condmsg:'Le courriel est obligatoire',condfx:function(val){return (val!=""); }},
          {datatype:'text',datavalue:tmain.banq_account.lien_parente,databind:'lien_parente',description:'Lien de parenté *',condvalue:false,condmsg:'Le lien de parenté est obligatoire',condfx:function(val){return (val!=""); }}          
        ],
        fxback:function(){
          tmain.ctrldataform={};
        },
        fxsave:function(o){
          tmain.banq_account.prenom = o.prenom;
          tmain.banq_account.nom = o.nom;
          tmain.banq_account.adresse = o.adresse;
          tmain.banq_account.cp = o.cp;
          tmain.banq_account.ville = o.ville;
          tmain.banq_account.telephone = o.telephone;
          tmain.banq_account.email = o.email;
          tmain.banq_account.lien_parente = o.lien_parente;
          tmain.refresh_docs();
          tmain.ctrldataform={};
        }
      };
    },
    editbanqfile: function(){
      var tmain = this;
      tmain.account.file_attestation = "";
      tmain.ctrldataform = {
        title:"Fournir le formulaire de procuration",
        controls:[
          {datatype:'link',description:'Etape 1 : Télécharger le formulaire vierge de procuration',link:'https://reservation-vilvolt.agglo-epinal.fr/media/procuration.doc'},
          {datatype:'file',databind:'file_attestation',description:'Etape 2 : Envoyer le formulaire de procuration complété et signé',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image',condfx:function(val){return (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1 ); }}
        ],
        fxback:function(){
          tmain.ctrldataform={};
        },
        fxsave:function(ctrl){
          engine.File_Upload(ctrl.file_attestation,tmain.banq_account.id_fichier,function(){
            tmain.ctrldataform={};
            tmain.refresh_docs();
          });          
        }        
      }
    },
    downloadFile:function(doctype){
      var tmain = this;
      if (doctype=="attestation"){doctype=tmain.banq_account.id_fichier;}
      engine.File_Download(doctype,function(pkgfile){
        var ctype = pkgfile.headers.map["content-type"];
        var extname = ".pdf";
        if (ctype[0]=="image/png"){extname=".png";}
        if (ctype[0]=="image/jpg"){extname=".jpg";}
        if (ctype[0]=="image/jpeg"){extname=".jpeg";}
        var fileURL = window.URL.createObjectURL(new Blob([pkgfile.data]));
        var download_link = document.createElement('a');
        download_link.href = fileURL;
        download_link.download = doctype + extname;
        download_link.target = "_blank";
        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);
      });
    },
    uploadFile: function(doctype){
      var tmain = this;
      tmain.account.file_cni = "";
      tmain.account.file_cni_titulaire = "";
      tmain.account.file_justificatif = "";
      tmain.account.file_rib = "";
      tmain.account.file_cmuc = "";
      tmain.account.file_passmobilite = "";
      tmain.account.file_attestation = "";

      if (doctype=="cni"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_cni',description:'CNI / Passeport (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_cni,'cni',function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
      if (doctype=="cni_titulaire"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_cni_titulaire',description:'CNI / Passeport (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_cni_titulaire,'cni_titulaire',function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
      if (doctype=="justificatif"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_justificatif',description:'Justificatif de domicile (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_justificatif,'justificatif',function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
      if (doctype=="rib"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_rib',description:'Relevé d\'Identité Bancaire (RIB - au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_rib,'rib',function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
      if (doctype=="cmuc"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_cmuc',description:'Attestation CMUC (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_cmuc,'cmuc',function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
      if (doctype=="passmobilite"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'html',description:'<p style="border:solid 2px red;padding:5px;">ATTENTION : Pour bénéficier de ce tarif à 24€/mois (264€/an), vous devez être détenteur d\'un <a target="_blank" href="https://www.lepilote.com/fr/tarifs/7/Fare/pass-integral-annuel-aix-marseille-provence/199?PartnerId=10&Frequency=THM_FARE_6">Pass Integral Annuel à 68€/mois</a> ou d\'un <a target="_blank" href="https://www.lecaraixmarseille.com/pass-metropole">Pass Métropole à 30,40€/mois</a>, en cours de validité. Aucun autre abonnement n\'est éligible à ce tarif.<br/><br/>NB : Seule l\'attestation d\'abonnement Pass Intégral ou Pass Métropole sera acceptée, la photo de votre carte de transport n\'a pas valeur de justificatif. Vous pouvez demander une attestation d\'abonnement sur le site RTM <a target="_blank" href="https://www.rtm.fr/attestation-abonnement">https://www.rtm.fr/attestation-abonnement</a>.</p>' },
            {datatype:'file',databind:'file_passmobilite',description:'Attestation Abonnement Intégral Annuel ou Pass Métropole (au format PDF ou image)',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_passmobilite,'passmobilite',function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
      if (doctype=="attestation"){
        tmain.ctrldataform = { 
          title:"Envoyer des pièces justificatives",
          controls:[
            {datatype:'file',databind:'file_attestation',description:'Procuration du titulaire du compte bancaire',condvalue:false,condmsg:'Le fichier doit être au format PDF ou image avec une taille < 5 Mo',condfx:function(val){return (val.size < 5242880 && (val.type=="application/pdf" || val.type.indexOf('png')>-1 || val.type.indexOf('jpg')>-1 || val.type.indexOf('jpeg')>-1)); }}
          ],
          fxback:function(){
            tmain.ctrldataform={};
          },
          fxsave:function(ctrl){
            engine.File_Upload(ctrl.file_attestation,tmain.banq_account.id_fichier,function(){
              tmain.ctrldataform={};
              tmain.refresh_docs();
            });          
          }
        };
      }
    },

    payment_start: function(){
      var tmain = this;
      if(tmain.account.fin_contrats >= tmain.datenow || tmain.account.nb_contrats>=3){
        alert("Reservation impossible, vous en avez déjà une en cours");
        tmain.$router.push('/board');
      }else{
      tmain.loading=true;
      tmain.list_prices[0].amount = tmain.lbl_amount;
        var pkgp = {
          materials:tmain.list_materials,
          agencies:tmain.list_agencies,
          prices:tmain.list_prices,
          datedebut:tmain.date_retrait,
          datefin:tmain.date_fin,
          options:{
            is_main_bank:tmain.account.is_main_bank,
            banq_account:tmain.banq_account
          }
        };
        engine.Reservation_Create(pkgp,function(pkgcreate){
          if(pkgcreate.code!=-1){
            var datenow = tmain.$moment().year();
            var amount = (tmain.lbl_amount*100);
            var reference = pkgcreate.data.replace('-','');
            //var reference = 'RSW-TEST01-'+tmain.$moment().format('HHmm');
            var mail = tmain.account.email;
            var data = {
              mel:mail,
              montant:amount,
              objet:'paiement de votre reservation vilvolt',
              refdet:reference,
              exer:datenow
            }

            engine.Payfip_init(data,function(pkg){
              if (pkg.code==0){
                window.open(pkg.data, "_self");
              }
            });
            
          }else{
            alert("erreur! impossible de créer la réservation");
          }
        });
      }
    },
    createHiddenInput: function(form,label,value){
      var hiddenField = document.createElement("input"); 
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", label);
      hiddenField.setAttribute("value", value);
      form.appendChild(hiddenField);
    },
    backAccount:function(){
      var tmain = this;
      tmain.$router.push('/board');
    },
    saison:function(date){
      var splitdate = date.split("-");
      var mois = Number(splitdate[1]);
      var jour = Number(splitdate[2]);

      var mois_basse = 9;
      var jour_basse = 21;
      var mois_haute = 3;
      var jour_haute = 21;

      var ret;
      if(mois < mois_basse && mois > mois_haute){
        ret = "haute";
        /*haute (entre avril et aout*/
      }else if(mois > mois_basse){
        ret = "basse";
        /* basse (avant mars)*/
      }else if(mois < mois_haute){
        ret = "basse";
        /* basse (après septembre)*/
      }else if(mois == mois_basse && jour <  jour_basse){
        ret = "haute";
        /* haute septembre */
      }else if(mois == mois_basse && jour >= jour_basse){
        ret = "basse";
        /* basse septembre */
      }else if(mois == mois_haute && jour >= jour_haute){
        ret = "haute";
        /* haute mars */
      }else if(mois == mois_haute && jour < jour_haute){
        ret = "basse";
        /* basse mars */
      }
      return ret;
    },
    goRDV: function(){
      var download_link = document.createElement('a');
      download_link.href = "https://www.clicrdv.com/le-velo-plus?intervention_ids[]=3307709";
      download_link.target = "_blank";
      document.body.appendChild(download_link);
      download_link.click();
      document.body.removeChild(download_link);
    },
    goNOSTOCK: function(){
      var tmain = this;
    
      tmain.loading=true;
      var data = {
        materials:tmain.list_materials,
        datedebut:tmain.date_retrait,
        datefin:tmain.date_fin
      }
      engine.Reservation_Nostock(data,function(){
        tmain.loading=false;
        tmain.viewdiv = "nostock_valid";
      });
    }
  }
}
</script>
<style>
</style>
