import Vue from 'vue';

class DataAPI {
    constructor() {
        this.URL = "https://reservation-vilvolt.agglo-epinal.fr/api";
    }

    Guid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    IsProduction() {
        return true;
    }

    Account_Login(login, pwd, callback) {
        var datas = { login: login, pwd: pwd };
        Vue.http.post(this.URL + '/v1/account/login', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Account_Logout(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/logout', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Account_Email_Exist(login, callback) {
        var datas = { login: login };
        Vue.http.post(this.URL + '/v1/account/exist', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Account_Info(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/info', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Account_Edit(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/account/edit', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Account_Edit_Bank(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/account/edit/bank', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Account_Create(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/account/create', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Account_Create_Valid(apikey, callback) {
        var datas = { apikey: apikey };
        Vue.http.post(this.URL + '/v1/account/create/valid', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Account_Recovery_Ask(email, callback) {
        var datas = { email: email };
        Vue.http.post(this.URL + '/v1/account/recovery/ask', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Account_Recovery_Valid(apikey, pwd, callback) {
        var datas = { apikey: apikey, pwd: pwd };
        Vue.http.post(this.URL + '/v1/account/recovery/valid', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Account_Inactive(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/inactive', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Account_Delete(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/account/delete', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Agency_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/agency/list', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Material_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/material/list', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Rentals_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/rentals/list', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Invoices_List(callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/invoices/list', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    File_Exist(doctype, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/exist/' + doctype, datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    File_Download(doctype, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/' + doctype, datas, {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Invoice_Download(number, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/invoice/' + number, datas, {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Rental_Download(number, callback) {
        var datas = {};
        Vue.http.post(this.URL + '/v1/file/download/rental/' + number, datas, {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Upload(file, doctype, callback) {
        var formData = new FormData();
        formData.append('file', file);
        Vue.http.post(this.URL + '/v1/file/upload/' + doctype, formData, {
            responseType: 'json',
            credentials: false,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    GetHmac(paquet, callback) {
        var datas = { paquet: paquet };
        Vue.http.post(this.URL + '/v1/hmac', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Payfip_init(data, callback) {
        var datas = { data: data };
        Vue.http.post(this.URL + '/v1/payfip/create', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Recaptcha_Send(recaptcha_user, callback) {
        var datas = { recaptcha_user: recaptcha_user };
        Vue.http.post(this.URL + '/v1/recaptcha', datas, {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(res.body); }, reserror => { callback(reserror); });
    }

    Reservation_Create(vdata, callback) {
        var datas = { source: vdata };
        Vue.http.post(this.URL + '/v1/reservation/create', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Reservation_Cancel(numero, callback) {
        var datas = { numero: numero };
        Vue.http.post(this.URL + '/v1/reservation/cancel', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

    Reservation_Nostock(vdata,callback) {
        var datas = {source: vdata};
        Vue.http.post(this.URL + '/v1/reservation/nostock', datas, {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.body);
        }, reserror => { callback(reserror); });
    }

}

export default new DataAPI()